<template>
  <div class="bg-vue">
    <!--   <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>-->
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>
//import VueCookies from "vue-cookies";
import { container } from "jenesius-vue-modal";
//import { openModal } from "jenesius-vue-modal";
//import { mapState } from "vuex"

export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        console.log("change from " + from);
      },
    },
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";

* {
  padding: 0;
  margin: 0;
}
:root {
  --hertwigRot: rgb(211, 0, 0);
  --hertwigBlau: #0027bd;
  --hertwigSilber: rgb(155, 154, 154);
}
p {
  font-size: 18px;
}
.font {
  font-variant: lining-nums;
}
.row {
  margin: 0;
}
ul {
  list-style: none;
}
h2 {
  font-size: 2rem;
}
.bgGrau15 {
  background-color: rgba(196, 195, 195, 0.75);
  padding: 40px;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.1);
}
.bgWeiss15 {
  background-color: rgba(255, 254, 254, 0.75);
}

.h1Weiss {
  color: rgba(255, 254, 254);
}

.h2Rot {
  color: var(--hertwigRot);
  text-shadow: none;
}
.h5AnkerLink {
  text-align: center;
  color: var(--hertwigRot);
  background-color: rgba(253, 253, 253, 0.705);
  text-decoration: none;
}
.textbalken {
  position: relative;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  /* background-color: rgba(253, 253, 253, 0.5);
  font-size: 20px;
  color: #404040;*/
  background-color: rgba(253, 253, 253, 0.705);
  font-size: 22px;
  font-weight: bold;
  color: var(--hertwigRot);
  /* padding: 10px;*/
}
.custom-control-label {
  padding-left: 20px;
}

.hoverBig:hover {
  border: 3px solid rgb(252, 251, 251);
  border-radius: 25px;
  position: relative;
  bottom: 0px;
}
@media screen and (max-width: 1350px) {
  .textbalken {
    font-size: 14px;
    bottom: 17px;
  }
}
@media screen and (max-width: 990px) {
  .textbalken {
    font-size: 12px;
    bottom: 10px;
  }
  .nav-deactiv {
    font-size: 12px;
  }
  .nav {
    display: block;
  }
  .animate-Title {
    font-size: 50px;
  }
}
@media screen and (max-width: 770px) {
  .textbalken {
    font-size: 22px;
    bottom: 25px;
  }
  .noHandy {
    display: none;
  }
  .animate-Title {
    font-size: 25px;
  }
  .videosize {
    width: 90%;
  }
  .pdiv5 {
    padding: 10px !important;
  }
}

@media screen and (min-width: 990px) {
  .titleHeight {
    min-height: 64px;
  }
  .animate-Title {
    font-size: 60px;
  }
  .videosize {
    width: 90%;
  }
  .title {
    color: var(--hertwigRot);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .titleFull {
    color: var(--hertwigRot);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .titleKlein {
    color: var(--hertwigRot);
    background-color: white;
    opacity: 0.85;
  }
  .pdiv5 {
    padding: 3rem !important;
  }
  .noDesktop{
    display: none;
  }
}

.rot {
  color: var(--hertwigRot);
}
.blau {
  color: var(--hertwigBlau);
}
.feldweiss,
.feldweiss h4 {
  background-color: white;
  color: black;
  padding: 15px;
}
.weissSschwarz {
  color: black !important;
  background-color: white;
  border-style: outset;
  border-width: 5px;
}
.grauHellfeld {
  color: black !important;
  background-color: rgb(206, 217, 248);
  border-style: outset;
  border-width: 5px;
}
.rotSweiss {
  color: var(--hertwigRot);
  text-shadow: 1px 1px 2px white;
}
.rotSgrau {
  color: var(--hertwigRot);
  text-shadow: 1px 1px 2px #404040;
}
.rotSgrauBig {
  font-size: 54px;
  color: var(--hertwigRot);
  text-shadow: 1px 1px 4px #404040;
}
.grauDunkelfeld {
  color: white;
  background-color: #404040;
  margin: 0;
  padding: 0;
}
.sticky {
  position: fixed;
  bottom: 50px;
  left: 0px;
  overflow: auto;
  max-height: 200px;
}
.stickyAusbildung {
  position: fixed;
  bottom: 0px;
  left: 0px;
  overflow: auto;
  color: var(--hertwigRot);
  text-shadow: 1px 1px 4px white;
  width: 250px;
  z-index: 99;
}
.stickyPfeil {
  position: fixed;
  bottom: 150px;
  left: 50px;
  overflow: auto;
  color: #e62d39 !important;
  text-shadow: 1px 1px 4px white;
  font-size: 56px;
  z-index: 99;
}
.stickyStellen {
  position: fixed;
  bottom: 250px;
  left: -510px;
  overflow: auto;
  color: var(--hertwigRot);
  text-shadow: 1px 1px 4px white;
  width: 550px;
  z-index: 99;
  transition-property: left;
  transition-duration: 1.5s;
}
.stickyStellen:hover {
  left: 0px;
}
.stickyStellenDetail {
  font-size: 30px;
  font-weight: bold;
  bottom: -50;
}
.stinkyStellenKurztext {
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: fixed;
  bottom: 267px;
  left: 10px;
  font-size: 11px;
}
.Kreuzpunkt {
  max-height: 30px;
  padding: 5px;
}

.titelBackground {
  background-image: url("assets/bg1b.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000;
  padding-left: 0px;
  padding-right: 0px;
}
.noOpacity {
  opacity: 1 !important;
  background-color: white !important;
}

.RefPicFirma {
  min-width: 300px;
  max-width: 300px;
}
.RefPicProduct {
  min-height: 200px;
  max-height: 200px;
  cursor: pointer;
}
.center {
  justify-content: center !important;
}

.imageToBig:hover {
  cursor: pointer;
}

.zubInnen {
  background-color: rgb(90, 90, 90);
  color: white;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  padding: 15px;
  text-align: center;
}
.zubInnenOver {
  background-color: white;
  color: rgb(90, 90, 90);
  border-color: rgb(90, 90, 90);
  border-style: solid;
  border-width: 2px;
  padding: 15px;
}
.zubRouterLink {
  text-decoration: none;
}

.inputerror {
  color: var(--hertwigRot);
}
.inputok {
  color: #228b4e;
}
.zitate {
  color: white;
}
.zitatverweis {
  color: white;
  font-weight: normal;
}

.normalerText {
  background-color: white;
  color: rgb(11, 12, 14);
  padding: 30px;
  opacity: 0.85;
}
.bg-vue {
  background-color: rgb(255, 255, 255);
  color: white;
}
.bg-main {
  background-color: var(--hertwigSilber);
  color: white;
}
.bg-center {
  background-color: white;
  color: #000;
  margin: 0;
  padding: 0;
  z-index: 9;
}
.bg-navbar {
  background-image: linear-gradient(
    to top,
    rgb(255, 255, 255) 0%,
    rgb(44, 62, 114) 100%
  );
  color: white !important;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
  text-transform: uppercase;
}
.bg-footer {
  background-image: linear-gradient(
    to top,
    rgb(44, 62, 114) 20%,
    rgb(255, 255, 255) 100%
  );
  color: black;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
}
.img-tuev {
  position: absolute;
  right: 0px;
  top: -0px;
  max-height: 50px;
}
.bg-footer p {
  margin: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.bg-footer a {
  color: black;
  text-decoration: none;
}
.linkSchwarz {
  color: black !important;
}
.nav-item {
  justify-content: center !important;
  text-align: center;
}
.nav-active {
  color: black !important;
  text-decoration: underline var(--hertwigBlau);
  font-size: 16px;
  font-weight: 900;
}
.nav-deactiv {
  text-decoration: none;
  color: black !important;
  font-size: 16px;
  font-weight: 700;
}
.HeaderLogo {
  max-height: 50px;
}

.text-vue {
  color: var(--hertwigBlau);
}
.text-vue2 {
  color: rgb(65, 184, 131);
}

.textLinksW {
  /* text-align: end; */
  /*margin-right: 50px;*/
  padding: 0;
  color: #acaaaa;
}
.ganzlinks {
  padding: 0;
}

.imgCH {
  position: relative;
}
.spanCH {
  position: absolute;
  bottom: 1px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  /* background-color: rgba(253, 253, 253, 0.5);
  font-size: 20px;
  color: #404040;*/
  background-color: rgba(253, 253, 253, 0.705);
  font-size: 20px !important;
  /* font-weight: bold;*/
  color: var(--hertwigRot);
  /* padding: 10px;*/
}

.form-check-input:checked {
  background-color: var(--hertwigRot);
  border-color: var(--hertwigRot);
}

.carousel__slide img {
  border: #000;
  border-style: solid;
  border-width: 1px;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slInLeft {
  animation-duration: 2s;
  animation-name: slideinLeft;
  animation-delay: 5s;
}
@keyframes slideinLeft {
  from {
    margin-left: 0%;
    width: 0%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.ele3_1 {
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  visibility: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

/* cyclopeEye */
.animate-Title {
  background-image: linear-gradient(
    -225deg,
    var(--hertwigRot) 0%,
    var(--hertwigRot) 25%,
    rgb(77, 0, 0) 50%,
    var(--hertwigRot) 75%,
    var(--hertwigRot) 100%
  );

  background-clip: border-box;
  background-size: 200% auto;

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 8s linear infinite;
  display: inline-block;
  font-weight: bolder;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Inconsolata|Oswald");
#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.vonLinks {
  -webkit-animation-name: vonLinks;
  -webkit-animation-duration: 5s;
  -moz-animation-name: vonLinks;
  -moz-animation-duration: 5s;
  animation-name: vonLinks;
  animation-duration: 5s;
}
@-webkit-keyframes vonLinks {
  from {
    opacity: 0;
    -webkit-transform: translate(1600px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, -0px);
  }
}

@-moz-keyframes vonLinks {
  from {
    opacity: 0;
    -moz-transform: translate(1600px, 0);
  }
  to {
    opacity: 1;
    -moz-transform: translate(0, -0px);
  }
}

@keyframes vonLinks {
  from {
    opacity: 0;
    transform: translate(1600px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, -0px);
  }
}

.stellenAktuell {
  -webkit-animation-name: stellenAktuell;
  -webkit-animation-duration: 5s;
  -moz-animation-name: stellenAktuell;
  -moz-animation-duration: 5s;
  animation-name: stellenAktuell;
  animation-duration: 6s;
}
@-webkit-keyframes stellenAktuell {
  0% {
    -webkit-transform: translate(-0px, 0px);
  }
  30% {
    -webkit-transform: translate(1000px, 0px);
  }
  70% {
    -webkit-transform: translate(1000px, 0px);
  }
  100% {
    -webkit-transform: translate(-0px, 0px);
  }
}

@-moz-keyframes stellenAktuell {
  0% {
    -webkit-transform: translate(-0px, 0px);
  }
  30% {
    -webkit-transform: translate(1000px, 0px);
  }
  70% {
    -webkit-transform: translate(1000px, 0px);
  }
  100% {
    -webkit-transform: translate(-0px, 0px);
  }
}

@keyframes stellenAktuell {
  0% {
    -webkit-transform: translate(-0px, 0px);
  }
  30% {
    -webkit-transform: translate(1000px, 0px);
  }
  70% {
    -webkit-transform: translate(1000px, 0px);
  }
  100% {
    -webkit-transform: translate(-0px, 0px);
  }
}
.einblenden1 {
  animation: einblenden 8.5s;
  -moz-animation: einblenden 8.5s; /* Für Firefox */
  -webkit-animation: einblenden 8.5s; /* Für Safari und Chrome */
  -o-animation: einblenden 8.5s; /* Für Opera */
}
@keyframes einblenden {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes einblenden {
  /* Für Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes einblenden {
  /* Für Safari und Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes einblenden {
  /* Für Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
</style>
