import { createRouter, createWebHistory } from "vue-router";
 
import routes from "./routes";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import store from "../store";

const router = createRouter({
  // Optionen
  history: createWebHistory(),
  mode: 'hash',
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes: [
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      component: NotFoundPage,
      redirect: "/",
    },
  ],
});

router.afterEach((to) => {
  store.commit('setActiveSite',to.fullPath);
  console.log(to);
  document.title = 'hertwig-cnc.de'+to.fullPath || 'hertwig-cnc.de';
})

export default router;

