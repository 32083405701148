import mainRoutes from "./main-router";
import impressumRoutes from "./impressum-router";
import datenschutzRoutes from "./datenschutz-router";
import produkteRoutes from "./produkte-router";
import leistungenRoutes from "./leistungen-router";
import referenzenRoutes from "./referenzen-router";
import stellenangeboteRoutes from "./stellenangebote-router";
import partnerRoutes from "./partner-router";
import kontaktRoutes from "./kontakt-router";
import sitemapRoutes from "./sitemap-router";

const routes = [...mainRoutes, 
                ...impressumRoutes, 
                ...datenschutzRoutes,
                ...produkteRoutes,
                ...leistungenRoutes,
                ...referenzenRoutes,
                ...stellenangeboteRoutes,
                ...partnerRoutes,
                ...kontaktRoutes,
                ...sitemapRoutes,
            ];

export default routes;
